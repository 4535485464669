<script setup lang="ts">
useMetaDefaultHead()
</script>

<template>
  <div class="border-b border-gray-200">
    <div class="container flex py-6">
      <AppLogo />
    </div>
    <main>
      <slot />
    </main>
    <footer class="border-t border-gray-200 bg-white">
      <div class="container py-6">
        <p class="text-center">
          &copy; {{ new Date().getFullYear() }} Yescapa
        </p>
      </div>
    </footer>
  </div>
</template>
